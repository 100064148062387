/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, SeparateLineWrap, Text, Divider, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--left pt--80" name={"cenik"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(50, 69, 52);\"><br>Ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"5qmeocw5q5x"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(50,69,52,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--40" name={"cenik-2"}>
          
          <ColumnWrap className="column__flex --center el--2 mb--40 flex--top" anim={null} animS={null} style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Zimní sezona"}>
              </Title>

              <Text className="text-box" content={"prosince - březen"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--26" content={"dospělý &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 450,- <br>dítě (3–6 let) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;400,-\n<br>dítě (1-3 roky) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;300,-"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mb--40 flex--top" anim={null} animS={null} style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Letní sezona"}>
              </Title>

              <Text className="text-box" content={"duben - listopad"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--26" content={"dospělý &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 400,- <br>dítě (3–6 let) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;350,-\n<br>dítě (1-3 roky) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;250,-"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mb--40 flex--top" anim={null} animS={null} style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Vánoční sezona<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--26" content={"dospělý &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 500,- <br>dítě (3–6 let) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;450,-<br>dítě (1-3 roky) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;350,-<br><br><span style=\"font-weight: bold;\">dítě do 1 roku &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; zdarma<br>pes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;100,-<br></span><br>"}>
              </Text>

              <Divider style={{"backgroundColor":"rgba(25,77,51,1)"}}>
              </Divider>

              <Text className="text-box fs--26" content={"příplatek za jednu noc                                                                                             \n<br><span style=\"font-weight: bold;\">100,-/osoba </span><br>Po domluvě lze zařídit stravování<br><span style=\"font-weight: bold;\">dospělý  300,- / dětská 200,- </span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-m2oo0 pb--80 pt--80" name={"rezervace"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--white);\">Zavolejte nebo napište na e-mail</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(50, 69, 52);\">PENZION HANA STRÁŽNÉ</span><br>"}>
              </Title>

              <Text className="text-box fs--26" content={"Adresa: Strážné 138, 543 52 Strážné <br>Telefonní číslo: +420 777 307 212 <br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; +420 605 519 619<br>"}>
              </Text>

              <Text className="text-box" content={"Nikola Brothánková <br>IČO: 06187901<br>SÍDLO: Strážné <br>Registrován v živnostenském rejstříku."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--22" content={"<span style=\"caret-color: rgb(50, 69, 52); color: rgb(50, 69, 52);\">pensionhanastrazne@seznam.cz</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":400}} content={"Nebojte se na nás obrátit s jakýmikoli dotazy.<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":157}} srcSet={"https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=350x_.png 350w, https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}